import React, { useEffect, useState } from "react";
import SideList from "../../../UI-component/SideList";
import ErrorAlert from "../../../UI-component/ErrorAlert";
import SummaryCard from "../../../UI-component/summary/SummaryCard";
import PopupTextField from "../../../UI-component/ui-el/PopupTextField";
import NextBtnForm from "../../../UI-component/NexBtnForm";
import SuccessfullPopup from "../../../UI-component/SuccessfullPopup";
import SelectionFormBox from "../../../UI-component/SelectionFormBox";
import CheckedFormBox from "../../../UI-component/CheckedFormBox";
import Loader from "../../../UI-component/Loader";
import { useAccess } from "./access";
import { getTenantCode } from "../../../util";

function Specific(props) {
    const {data, values, loading, error, isValidStep, update, updateField, save, savePrefs} = useAccess(props.save);

    const onNext = () => {
        props.next();
    }

    const shouldShowAddressField = data?.modalita === "Invio tramite raccomandata";
    const shouldShowPECField = data?.modalita === "Invio tramite PEC"
    const shouldShowMarca = values?.extra_declarations ? data?.chiede_extra === values?.extra_declarations?.[3]?.value : false;
    const tenantCode = getTenantCode();
    
    const getInfoList = () => {
        switch(tenantCode){
            case "L337":
                return [
                    { element: "Tipologia", id: "#report-author" },
                    { element: "Modalità di ritiro", id: "#report-author" },
                    shouldShowPECField
                    ? { element: "Indirizzo PEC", id: "#report-author" }
                    : {},
                    { element: "Motivo", id: "#report-author" },
                    { element: "Chiede", id: "#report-author" },
                    { element: "Chiede", id: "#report-author" },
                    shouldShowMarca
                    ? { element: "Marca da bollo", id: "#report-author" }
                    : {},
                    { element: "Dichiaro", id: "#report-author" },
                ]
            default: 
                return [
                    { element: "Tipologia", id: "#report-author" },
                    { element: "Modalità di ritiro", id: "#report-author" },
                    shouldShowAddressField
                        ? { element: "Indirizzo di spedizione", id: "#report-author" }
                        : {},
                    { element: "Motivo", id: "#report-author" },
                    { element: "Chiede", id: "#report-author" },
                    { element: "Dichiaro", id: "#report-author" },
                ]
        }
    }
    const renderExtraContent = () => {
        switch(tenantCode){
            case "L337":
                return(
                    <>
                        {values?.extra_declarations?.length > 0 && 
                            <SelectionFormBox
                                showTitle={"Chiede*"}
                                id="chiede_extra"
                                title="Chiede"
                                description=""
                                selectTitle="Seleziona una tipologia"
                                choices={values?.extra_declarations || []}
                                defaultValue={data?.chiede_extra}
                                oKey={"Ufficio"}
                                objectify={true}
                                update={update}
                            />
                        }

                        {shouldShowMarca &&
                            <SummaryCard type="Marca da bollo*">
                                <PopupTextField
                                    defaultValue={data?.marca_bollo?.numero}
                                    onChange={(e) => { updateField(e, "marca_bollo", true)}}
                                    id="numero"
                                    fieldType="text"
                                    fieldTitle="Numero"
                                    description=""
                                    regex="^[0-9]{14}$"
                                    max="14"
                                    min="14" />
                                <PopupTextField
                                    defaultValue={data?.marca_bollo?.data}
                                    onChange={(e) => { updateField(e, "marca_bollo", true)}}
                                    id="data"
                                    fieldType="date"
                                    fieldTitle="Data"
                                    description=""
                                    min={new Date().toISOString().split('T')[0]}
                                />
                            </SummaryCard>
                        }
                    </>
                )
            default:
                return null;
        }
    }
    return (
        <div className="row">
            <SideList infoList={getInfoList()} />
            <div className="col-12 col-lg-8 offset-lg-1">
                <div className="steppers-content" aria-live="polite">
                    <div className="it-page-sections-container">
                        {
                            <ErrorAlert errors={[{ name: "Tipologia", id: "#isee-info" }]} err={error.tipologia} />
                        }
                        {
                            <ErrorAlert errors={[{ name: "Modalità di ritiro", id: "#isee-info" }]} err={error.modalita} />
                        }
                        {
                            <ErrorAlert errors={[{ name: "Chiede", id: "#isee-info" }]} err={error.chiede} />
                        }
                        {
                            <ErrorAlert errors={[{ name: "Motivo", id: "#isee-info" }]} err={error.motivo} />
                        }
                        {
                            <ErrorAlert errors={[{ name: "Dichiaro", id: "#isee-info" }]} err={error.dichiaro} />
                        }


                        <div style={{ position: "fixed", top: "50%", left: "50%", transform: "translate(-55%,-50%)", zIndex: "10000" }}>
                            <Loader loading={loading} />
                        </div>

                        <SelectionFormBox
                            showTitle={"Tipologia*"}
                            title="Tipologia"
                            description="Scegli la tipologia"
                            selectTitle="Seleziona una tipologia"
                            choices={[
                                { id: 1, value: "Richiesta di accesso civico generalizzato" },
                                { id: 2, value: "Richiesta di accesso agli atti amministrativi" }
                            ]}
                            defaultValue={data?.tipologia}
                            oKey={"Ufficio"}
                            objectify={true}
                            update={update}
                        />

                        <SelectionFormBox
                            showTitle={"Modalità di ritiro*"}
                            title="Modalita"
                            description="Indica come preferisci ritirare la copia degli atti richiesti"
                            selectTitle="Seleziona una modalità"
                            choices={values?.modes}
                            defaultValue={data?.modalita}
                            oKey={"Ufficio"}
                            objectify={true}
                            update={update}
                        />
                        {shouldShowAddressField && (
                            <SummaryCard type="Indirizzo di spedizione">
                                <PopupTextField
                                    defaultValue={data?.indirizzo_spedizione}
                                    fieldType="text"
                                    id="indirizzo_spedizione"
                                    straightErr={error?.modalita}
                                    fieldTitle="Indirizzo"
                                    description="Indica l'indirizzo a cui verrà spedita copia degli atti richiesti"
                                    onChange={(e) => { updateField(e, "indirizzo_spedizione") }}
                                />
                            </SummaryCard>
                        )}
                        {shouldShowPECField && (
                            <>
                                <SummaryCard type="Indirizzo PEC" title="Indirizzo PEC">
                                    <PopupTextField
                                        defaultValue={data?.indirizzo_pec}
                                        fieldType="email"
                                        id="indirizzo_pec"
                                        fieldTitle="Indirizzo PEC"
                                        // straightErr={error?.modalita}
                                        description="Indica l'indirizzo PEC a cui verrà inviata la copia degli atti richiesti"
                                        onChange={(e) => { updateField(e, "indirizzo_pec") }}
                                    />
                                </SummaryCard>
                            </>
                        )}

                        <SummaryCard type="Motivo*">
                            <PopupTextField
                                defaultValue={data?.motivo}
                                fieldType="text"
                                id="motivo"
                                fieldTitle="Motivo"
                                description="Aggiungi un motivo alla tua richiesta"
                                onChange={(e) => { updateField(e, "motivo") }}
                            />
                        </SummaryCard>

                        <SummaryCard
                            type="Chiede*"
                            description="(ai sensi e per gli effetti dell’art. 5, c.1, D.Lgs. n. 33/2013, delle relative disposizioni di attuazione dell’Amministrazione, disciplinanti il diritto di accesso ai dati e documenti detenuti dall’Amministrazione)">
                            <PopupTextField
                                defaultValue={data?.chiede?.documento}
                                fieldType="text"
                                id="documento"
                                fieldTitle="Documento"
                                description="Inserisci il documento"
                                onChange={(e) => { updateField(e, "chiede", true) }} />
                            <PopupTextField
                                defaultValue={data?.chiede?.informazione}
                                fieldType="text"
                                id="informazione"
                                fieldTitle="Informazione"
                                description="Inserisci l'informazione"
                                onChange={(e) => { updateField(e, "chiede", true) }} />
                            <PopupTextField
                                defaultValue={data?.chiede?.dato}
                                fieldType="text"
                                id="dato"
                                fieldTitle="Dato"
                                description="Inserisci il dato"
                                onChange={(e) => { updateField(e, "chiede", true) }}
                            />
                        </SummaryCard>

                        {renderExtraContent()}
                        
                        <CheckedFormBox
                            type="checkbox"
                            title={["Accesso", "Dichiaro"]}
                            description=""
                            choices={values?.declarations || []}
                            defaultValue={data?.dichiaro}
                            update={update}
                        />

                    </div>
                </div>
                <NextBtnForm
                    next={onNext}
                    back={props.back}
                    saveRequest={save}
                    disabled={!isValidStep("specific")}
                />
                {
                    props.saved &&
                    <SuccessfullPopup />
                }

                {
                    props.fail &&
                    <SuccessfullPopup
                        isError={true}
                    />
                }
            </div>
        </div>
    )
}

export default Specific;